(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.BIG_DESKTOP_WIDTH = 1200;
  window.CONTENT_WIDTH = 1262;

  // selectors
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');


  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/iPad|iPhone|iPod|android|webOS|Windows Phone|BlackBerry|IEMobile|Opera Mini/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW.on('resize', function() {
    var windowWidth = window.innerWidth || $WINDOW.width();

    window.OLD_WINDOW_WIDTH = window.WINDOW_WIDTH || windowWidth;
    window.WINDOW_WIDTH = windowWidth;

    window.WINDOW_HEIGHT = $WINDOW.height();
    window.HEADER_HEIGHT = $HEADER.height();
  })
  .trigger('resize');

  /**
   * @return boolean
   */
  window.IS_MORE_THAN_CONTENT_WIDTH = function() {
    return WINDOW_WIDTH >= CONTENT_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_BIG_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= BIG_DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    },
    'number-mask': {
      mask: '9{*}',
      greedy: false
    },
    'float-mask': {
      mask: '9{*}[(.|,)9{1,2}]',
      greedy: false
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');

  // Number
  $('.js-number-mask').inputmask('number-mask');

  // Float number
  $('.js-float-mask').inputmask('float-mask');

  // Not empty
  $('.js-not-empty').on('blur', function() {
    var $element = $(this),
        value = $element.val(),
        defaultValue = $element.data('defaultValue');

    if( !$.isNumeric(defaultValue) )
      defaultValue = 1;

    if( value == '' )
      $element.val(defaultValue);
  });


  // Spinner Widget
  // --------------
  $.widget('ui.spinner', $.ui.spinner, {
    _uiSpinnerHtml: function() {
      return '<div class="ui-spinner js-spinner"></div>';
    },
    _uiSpinnerWrapperHtml: function() {
      return '<div class="js-spinner-input-wrapper"></div>';
    },
    _buttonDownHtml: function() {
      return '<a class="ui-spinner-button ui-spinner-down js-spinner-button js-spinner-button-down"><span>&#8211;</span></a>';
    },
    _buttonUpHtml: function() {
      return '<a class="ui-spinner-button ui-spinner-up js-spinner-button js-spinner-button-up"><span>&#43;</span></a>';
    },
    _draw: function() {
      var uiSpinner = this.uiSpinner = this.element
        .addClass('ui-spinner-input')
        .attr('autocomplete', 'off')
        .wrap(this._uiSpinnerWrapperHtml())
        .parent()
        .wrap(this._uiSpinnerHtml())
        .parent()
          // add buttons
          .prepend(this._buttonDownHtml())
          .append(this._buttonUpHtml());

      this.element.attr('role', 'spinbutton');

      // button bindings
      this.buttons = uiSpinner.find('.ui-spinner-button')
        .attr('tabIndex', -1)
        .button()
        .removeClass('ui-corner-all');

      // disable spinner if element was already disabled
      if( this.options.disabled ) {
        this.disable();
      }
    }
  });


  if( WINDOW_WIDTH >= 500 ) {
    $DOCUMENT
      // Scrolling to top
      // ----------------
      .ready(function() {
        var $goTopBtn = $('#go-top-button');

        $WINDOW.on('scroll.goTopButton', function(e, goTopBtnClass) {
          if( goTopBtnClass === undefined )
            goTopBtnClass = 'top-button-visible';

          if( $WINDOW.scrollTop() > HEADER_HEIGHT ) {
            $goTopBtn.addClass(goTopBtnClass);
          } else {
            $goTopBtn.removeClass();
          }
        }).trigger('scroll.goTopButton', ['top-button-custom-visible']);
      })
      .on('click.goTopButton', '#go-top-button', function(e) {
        e.preventDefault();

        $WINDOW.scrollTo(0, 200);
      });
  }

  if( !IS_DESKTOP_WIDTH() ) {
    $DOCUMENT
      .ready(function() {
        // Move left filters
        // -----------------
        $('.product-list-filters').prependTo('.product-list-filters-placeholder > div + div');
      })

      .on('click.productListFiltersCaption', '.product-list-filters-placeholder > div:first-child, .filter-caption', function(e) {
        e.preventDefault();

        $(this).toggleClass('is-opened');
      });
  }

  if( IS_BIG_DESKTOP_WIDTH() ) {
    $DOCUMENT
      .ready(function() {
        $WINDOW.on('scroll.headerButtons', function() {
          var $headerButtons = $('#js-header-buttons.is-fixed-allowed');

          if( $headerButtons.length && $WINDOW.scrollTop() >= $headerButtons.offset().top ) {
            if( !$headerButtons.hasClass('initialized') ) {
              $headerButtons.children().clone().appendTo($headerButtons);
              $headerButtons.addClass('initialized');
            }

            $headerButtons.addClass('is-fixed');
          } else {
            $headerButtons.removeClass('is-fixed');
          }
        }).trigger('scroll.headerButtons');

        // Fix header submenu popup position
        // ---------------------------------
        var $headerMenu = $('.header-menu-2-container > .header-menu'),
            headerMenuWidth = $headerMenu.outerWidth();

        if( !IS_MORE_THAN_CONTENT_WIDTH() )
          headerMenuWidth -= 20;

        $headerMenu.find('nav > ul > li').each(function() {
          var $item = $(this),
              $submenu = $(this).children('div, ul'),
              itemPositionLeft = $item.offset().left - $headerMenu.offset().left;

          if( !IS_MORE_THAN_CONTENT_WIDTH() )
            itemPositionLeft -= 10;

          if( $submenu.length ) {
            $submenu.css('max-width', headerMenuWidth);

            if( $submenu.outerWidth() + itemPositionLeft > headerMenuWidth )
              $submenu.css('left', 0 - ($submenu.outerWidth() + itemPositionLeft - headerMenuWidth));
          }
        });
      })

      // Search input focus
      // ------------------
      .on('focusin', '.header-search-container', function() {
        $(this).addClass('header-search-focus');
      })
      .on('focusout', '.header-search-container', function() {
        $(this).removeClass('header-search-focus');
      });
  }
  else {
    // Header menu button
    // ------------------
    $MENU_CHECKBOX
      .on('change', function() {
        if( this.checked ) {
          $BODY.addClass('menu-is-opened');
        } else {
          $BODY.removeClass('menu-is-opened');
        }
      })
      .prop('checked', false)
      .prop('disabled', false)
      .trigger('change');

    $DOCUMENT
      .ready(function() {
        // Move search box to menu
        // -----------------------
        $('.header-search-container').prependTo('.header-menu-2-container > .header-menu');
        $('.header-menu-2-container .header-info-2-menu > ul > li').prependTo('.header-menu-1-container nav .info-item > ul');
        $('.header-menu-1-container nav > ul > li').appendTo('.header-menu-2-container .header-product-menu > ul');

        // Header fixed
        $DOCUMENT.trigger('initHeaderFixed');
        $WINDOW.on('resize', function() { $DOCUMENT.trigger('initHeaderFixed'); });
      })

      // Header fixed
      // ------------
      .on('initHeaderFixed', function() {
        $('#structure').css('padding-top', HEADER_HEIGHT);
        $HEADER.addClass('header-fixed');
      })

      // Close header menu
      // -----------------
      .on('click.closeHeaderMenu', function(e) {
        if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-search-container').length || $(e.target).closest('.header-product-menu').length) ) {
          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          e.stopPropagation();
        }
      })

      // Header menu items
      .on('click.headerMenu', '.header-menu-2-container .header-product-menu > ul li > a, .header-menu-2-container .header-product-menu ul li > span', function(e) {
        e.preventDefault();

        var $self = $(this),
            $parent = $self.closest('li'),
            isWithDropdown = $parent.hasClass('with-dropdown'),
            isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings('.with-dropdown').removeClass('is-hovered').find('> div > div > ul, > ul').slideUp(300);

        if( isWithDropdown ) {
          if( isOnHover ) {
            if( $self.prop("tagName").toUpperCase() === 'A' )
              location.href = $self.prop('href');
            else
              $parent.removeClass('is-hovered').find('> div > div > ul, > ul').slideUp(300);
          } else {
            $parent.addClass('is-hovered').find('> div > div > ul, > ul').slideDown(300);
          }
        } else {
          if( $self.prop("tagName").toUpperCase() === 'A' )
            location.href = $self.prop('href');
        }
      });
  }


  $DOCUMENT
    .ready(function() {
      $('.footer').css('paddingBottom', $('.footer-sticky-panel').addClass('fixed').height());

      if( WINDOW_WIDTH >= 530 ) {
        // Catalog slide rotator
        // ---------------------
        $('.product-list-rotator-container > .cycle-slideshow').cycle();
      }

      // Product gallery rotator
      // -----------------------
      $('.product-card-gallery > .cycle-slideshow')
        .on('cycle-initialized', function() {
          $(this).addClass('cycle-initialized');
        })
        .cycle();

      // Compare list init
      $DOCUMENT.trigger('initCompareRotator');
      $WINDOW.on('resize', function() {
        if( WINDOW_WIDTH !== OLD_WINDOW_WIDTH ) {
          var $container = $('.compare-list-view .list-view-loading-container.cycle-initialized');

          if( $container.length ) {
            var opts = $container.data('cycle.opts');
            opts.currSlide = 0;
            $container.data('cycle.opts', opts);
            $('.compare-list-view .items, .compare-parameters-table-values').css('left', 0);
            $('.compare-cycle-prev.is-enabled').addClass('is-hidden');
            $('.compare-cycle-next.is-enabled').removeClass('is-hidden');
          }
        }
      });
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || $elemToScroll.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || $elemToScroll.data('scrollOffset') || 0;

      if( !IS_BIG_DESKTOP_WIDTH() )
        offset -= HEADER_HEIGHT;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs .tabs-nav li a', function(e) {
      e.preventDefault();

      var $lnk = $(this);

      if( !$lnk.hasClass('active') ) {
        var $destination = $($lnk.parents('.js-tabs').data('tabDest'));

        $lnk.closest('li').addClass('active').siblings().removeClass('active');
        $destination.children().eq($lnk.parent().index()).addClass('active').siblings().removeClass('active');
      }
    })

    // Показ деталей товара на разводных
    // ---------------------------------
    .on('click.productDetailsShow', '.js-show-product-details', function(e) {
      e.preventDefault();

      var $self = $(this),
          dataBlock = $self.closest('.product-block-container').find('.product-details-container'),
          dataBlockContent = dataBlock.find('.js-product-details-data');

      if( dataBlockContent.html() == '' ) {
        $.mouseLoader(true);
        $.ajax({
          url: $self.attr('href'),
          success: function(response) {
            dataBlockContent.html(response);
            dataBlockContent.find('.fancybox.fancybox-img').removeClass('fancybox-img');
            dataBlockContent.find('.js-spinner-input').trigger('initSpinner');
            dataBlockContent.find('.js-number-mask').inputmask('9{*}', {greedy: false});
            dataBlockContent.find('.cycle-slideshow').cycle();

            $.mouseLoader(false);
            $.fancybox(dataBlock);
          },
          error: function() {
            $.mouseLoader(false);
            alert("Во время загрузки данных возникла ошибка, попробуйте позже.");
          }
        });
      } else {
        $.fancybox(dataBlock);
      }
    })

    // Catalog
    // -------
    // init selectric
    .on('initSelectric', function() {
      $('select').selectric({
        disableOnMobile: false,
        nativeOnMobile: true
      });
    })
    .on('yiiListViewUpdated', function() {
      $DOCUMENT.trigger('initSelectric');
    })
    // init spinner
    .on('initSpinner basketListUpdated', function() {
      $('.js-spinner-input')
        .each(function() {
          var $spinner = $(this),
              min = $spinner.data('min') || 1,
              max = $spinner.data('max') || null,
              step = $spinner.data('step') || 1;

          $spinner
            .val(function(index, value) {
              if( value < min )
                return min;

              return value;
            })
            .spinner({
              culture: 'en',
              min: min,
              max: max,
              page: 0,
              step: step,

              change: function(e, ui) {
                e.preventDefault();

                var _min = $(e.target).data('min') || 1;

                if( $(e.target).val() < _min )
                  $(e.target).val(_min).trigger('change');
              },
              spin: function(e, ui) {
                e.preventDefault();

                $(e.target).val(ui.value).trigger('change');
              }
            });
        })
        .off('cut copy paste')
        .on('cut copy paste', function(e) {
          e.preventDefault();
        });
    })

    // Compare scripts
    // ---------------
    .on('click.compareParametersTabs', '.compare-parameters-tabs li a', function(e) {
      e.preventDefault();

      var $this = $(this);

      if( !$this.hasClass('active') && !$this.hasClass('is-disabled') ) {
        var $parameterContainers = $('.compare-parameters-table');

        $this.closest('li').addClass('active').siblings().removeClass('active');

        if( $this.data('showAllParameters') === true ) {
          $parameterContainers.addClass('is-opened');
        }
        else {
          $parameterContainers.removeClass('is-opened');
        }
      }
    })
    .on('initCompareRotator', function() {
      var carouselVisible = (IS_LARGE_PORTRAIT_MOBILE_WIDTH() ? 2 : (IS_MOBILE_WIDTH() ? 3 : (!IS_DESKTOP_WIDTH() ? 4 : (!IS_BIG_DESKTOP_WIDTH() ? 5 : 6))));

      $('.compare-list-view .list-view-loading-container')
        .on('cycle-initialized', function(event, opts) {
          $(this).addClass('cycle-initialized').children('.items-temp').remove();
          if( opts.slideCount > carouselVisible ) {
            $('.compare-list-controls > div').addClass('is-enabled');
          }
        })
        .cycle({
          'log': false,
          'autoHeight': false,
          'fx': 'carousel',
          'carouselVisible': carouselVisible,
          'carouselFluid': false,
          'carouselWrapClass': 'items',
          'carouselAnimatingStep': 'CALLBACKS.compareProductListAnimatingStep(now, tween);',
          'slides': '> .items-temp > div',
          'slideClass': '',
          'prev': '.compare-list-controls > .compare-cycle-prev',
          'next': '.compare-list-controls > .compare-cycle-next',
          'disabledClass': 'is-hidden',
          'pagerTemplate': '',
          'timeout': 0,
          'updateView': 1,
          'allowWrap': false
        });
    })
    .trigger('initSelectric')
    .trigger('initSpinner');


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    width: 'auto',
    height: 'auto',

    minWidth: 0,
    minHeight: 0,

    padding: 0,
    margin: 18,

    autoSize: false,

    fitToView: true,

    scrolling: 'auto',

    closeBtn: true,
    closeClick: false,
    nextClick: false,
    modal: false,
    loop: true,

    openEffect: 'none',
    closeEffect: 'none',

    helpers: {
      overlay: {
        closeClick: true,
        speedOut: 0,
        showEarly: true,
        locked: true
      }
    },

    beforeLoad: function () {
      var fancyboxMobileWidth;

      if( IS_WIDTH_LESS_PORTRAIT_TABLET() ) {
        if( (fancyboxMobileWidth = $(this.content).data('fancyboxMobileWidth')) != undefined ) {
          this.width = fancyboxMobileWidth;
          this.autoWidth = false;
        }
      }

      this.title = $(this.element).attr('data-caption');

      return true;
    },

    beforeShow: function () {
      $.fancybox.wrap.bind('contextmenu', function(e) {
        return false;
      });

      return true;
    },

    afterLoad: function () {
      $.fancybox.helpers.overlay.overlay.bind('contextmenu', function(e) {
        return false;
      });

      return true;
    },

    afterShow: function () {
      $.fancybox.wrap.find('.js-autofocus-inp').focus().select();

      return true;
    }
  });

  $('.fancybox').fancybox();

  window.fancyBoxConfirm = function(text, buttons, callbacks) {
    var contentButtons = '';

    if( text == undefined || text == null )
      text = 'Вы уверены?';

    if( buttons == undefined || buttons == null || typeof buttons != 'object' || typeof buttons == 'object' && Object.keys(buttons).length == 0 )
      buttons = {yes: 'Да', no: 'Нет'};

    if( callbacks == undefined || callbacks == null || typeof callbacks != 'object' || typeof callbacks == 'object' && Object.keys(callbacks).length == 0 )
      callbacks = {
        onYes: function() {},
        onNo: function() {}
      };
    else
      callbacks = $.extend(true, {onYes: function() {}, onNo: function() {}}, callbacks);

    if( Object.keys(callbacks).length < Object.keys(buttons).length )
      throw new Error('"callbacks" object contains fewer methods than the number of items in the "buttons" object!');

    $.each(buttons, function(key, value) {
      var keyCapital = key.charAt(0).toUpperCase() + key.slice(1);
      if( typeof callbacks['on' + keyCapital] == undefined || typeof callbacks['on' + keyCapital] != 'function' )
        throw new Error('"on' + keyCapital + '" method is not found in the object "callbacks"!');

      contentButtons += '<div id="fancybox-confirm-button-' + key + '" class="button-style fancybox-confirm-button" data-fancybox-confirm-callback="on' + keyCapital + '">' + value + '</div>';
    });

    contentButtons = '<div id="fancybox-confirm-buttons">' + contentButtons + '</div>';

    $.fancybox({
      type : 'html',
      modal : true,
      content : '<div class="fancybox-confirm-wrapper"><p class="fancybox-confirm-text">' + text + '</p>' + contentButtons + '</div>',

      beforeShow : function() {
        $.fancybox.wrap.bind('contextmenu', function(e) {
          return false;
        });

        $('#fancybox-confirm-buttons').on('click', '.fancybox-confirm-button', function() {
          $.fancybox.close();

          callbacks[$(this).data('fancybox-confirm-callback')]();
        });
      }
    });
  };


  // Callbacks
  // ---------
  CALLBACKS['compareProductListAnimatingStep'] = function(now, tween) {
    $('.compare-parameters-table-values').css(tween.prop, now);
  };
})();